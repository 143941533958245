import LocalStorageManager from "@/LocalStorageManager";

import paymentRouterPath from "@/router/paymentRouterPath.js";
import menuId from "@/constants/menuId";
import sideMenuId from "./sideMenuId";

const isAdmin = LocalStorageManager.shared.hasAdmin();
const sideMenuDataAdmin = [
  {
    title: "내 문서함",
    link: paymentRouterPath.PAYMENT_DRAFT_ING,
    menuId: [menuId.PAYMENT],
    sideMenuId: sideMenuId.DRAFT,
    arrSub: [
      {
        title: "저장 문서함",
        link: paymentRouterPath.PAYMENT_DRAFT_TEMP,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.DRAFT_TEMP,
      },
      {
        title: "진행중 문서함",
        link: paymentRouterPath.PAYMENT_DRAFT_ING,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.DRAFT_ING,
      },
      {
        title: "완료된 문서함",
        link: paymentRouterPath.PAYMENT_DRAFT_COMPLETE,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.DRAFT_COMPLETE,
      },
      {
        title: "반려된 문서함",
        link: paymentRouterPath.PAYMENT_DRAFT_REJECT,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.DRAFT_REJECT,
      },
    ],
  },
  {
    title: "내 결재함",
    link: paymentRouterPath.PAYMENT_APPROVAL_RECEIVE,
    menuId: [menuId.PAYMENT],
    sideMenuId: sideMenuId.APPROVAL,
    arrSub: [
      {
        title: "결재전 문서함",
        link: paymentRouterPath.PAYMENT_APPROVAL_BEFORE,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.APPROVAL_BEFORE,
      },
      {
        title: "승인할 문서함",
        link: paymentRouterPath.PAYMENT_APPROVAL_RECEIVE,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.APPROVAL_RECEIVE,
      },
      {
        title: "승인한 문서함",
        link: paymentRouterPath.PAYMENT_APPROVAL_ING,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.APPROVAL_ING,
      },
      {
        title: "완료된 문서함",
        link: paymentRouterPath.PAYMENT_APPROVAL_COMPLETE,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.APPROVAL_COMPLETE,
      },
      {
        title: "반려한 문서함",
        link: paymentRouterPath.PAYMENT_APPROVAL_REJECT,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.APPROVAL_REJECT,
      },
    ],
  },
  {
    title: "참조 문서함",
    link: paymentRouterPath.PAYMENT_CC_ING,
    sideMenuId: sideMenuId.CC,
    menuId: [menuId.PAYMENT],
    arrSub: [
      {
        title: "진행중 문서함",
        link: paymentRouterPath.PAYMENT_CC_ING,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.CC_ING,
      },
      {
        title: "처리된 문서함",
        link: paymentRouterPath.PAYMENT_CC_COMPLETE,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.CC_COMPLETE,
      },
    ],
  },
  {
    title: "관리",
    link: paymentRouterPath.PAYMENT_SYSTEM,
    menuId: [menuId.PAYMENT, menuId.PAYMENT_SYSTEM],
    sideMenuId: sideMenuId.MANAGEMENT,
    //requiresAuth: true,
    arrSub: [
      {
        title: "공용결재선 관리",
        link: paymentRouterPath.PAYMENT_SYSTEM_PUBLIC_LINE_LIST,
        menuId: [menuId.PAYMENT, menuId.PAYMENT_SYSTEM],
        sideMenuId: sideMenuId.MANAGEMENT_PUBLIC_APPROVAL_LINE,
        //requiresAuth: true,
      },
      {
        title: "결재선 관리",
        link: paymentRouterPath.PAYMENT_SYSTEM_ACCOUNT_LIST,
        menuId: [menuId.PAYMENT, menuId.PAYMENT_SYSTEM],
        sideMenuId: sideMenuId.MANAGEMENT_ACCOUNT,
        //requiresAuth: true,
      },
      {
        title: "가이드 관리",
        link: paymentRouterPath.PAYMENT_SYSTEM_USE_GUIDE_LIST,
        menuId: [menuId.PAYMENT, menuId.PAYMENT_SYSTEM],
        sideMenuId: sideMenuId.MANAGEMENT_USE_GUIDE,
        //requiresAuth: true,
      },
    ],
  },
];

const sideMenuDataUser = [
  {
    title: "내 문서함",
    link: paymentRouterPath.PAYMENT_DRAFT_ING,
    menuId: [menuId.PAYMENT],
    sideMenuId: sideMenuId.DRAFT,
    arrSub: [
      {
        title: "저장 문서함",
        link: paymentRouterPath.PAYMENT_DRAFT_TEMP,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.DRAFT_TEMP,
      },
      {
        title: "진행중 문서함",
        link: paymentRouterPath.PAYMENT_DRAFT_ING,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.DRAFT_ING,
      },
      {
        title: "완료된 문서함",
        link: paymentRouterPath.PAYMENT_DRAFT_COMPLETE,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.DRAFT_COMPLETE,
      },
      {
        title: "반려된 문서함",
        link: paymentRouterPath.PAYMENT_DRAFT_REJECT,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.DRAFT_REJECT,
      },
    ],
  },
  {
    title: "내 결재함",
    link: paymentRouterPath.PAYMENT_APPROVAL_RECEIVE,
    menuId: [menuId.PAYMENT],
    sideMenuId: sideMenuId.APPROVAL,
    arrSub: [
      {
        title: "결재전 문서함",
        link: paymentRouterPath.PAYMENT_APPROVAL_BEFORE,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.APPROVAL_BEFORE,
      },
      {
        title: "승인할 문서함",
        link: paymentRouterPath.PAYMENT_APPROVAL_RECEIVE,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.APPROVAL_RECEIVE,
      },
      {
        title: "승인한 문서함",
        link: paymentRouterPath.PAYMENT_APPROVAL_ING,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.APPROVAL_ING,
      },
      {
        title: "완료된 문서함",
        link: paymentRouterPath.PAYMENT_APPROVAL_COMPLETE,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.APPROVAL_COMPLETE,
      },
      {
        title: "반려한 문서함",
        link: paymentRouterPath.PAYMENT_APPROVAL_REJECT,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.APPROVAL_REJECT,
      },
    ],
  },
  {
    title: "참조 문서함",
    link: paymentRouterPath.PAYMENT_CC_ING,
    sideMenuId: sideMenuId.CC,
    menuId: [menuId.PAYMENT],
    arrSub: [
      {
        title: "진행중 문서함",
        link: paymentRouterPath.PAYMENT_CC_ING,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.CC_ING,
      },
      {
        title: "처리된 문서함",
        link: paymentRouterPath.PAYMENT_CC_COMPLETE,
        menuId: [menuId.PAYMENT],
        sideMenuId: sideMenuId.CC_COMPLETE,
      },
    ],
  },
];

let sideMenuData = isAdmin ? sideMenuDataAdmin : sideMenuDataUser;

export default sideMenuData;
