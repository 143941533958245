<template>
  <ul class="list_gnb">
    <template v-for="menuData in menuDatas">
      <li v-if="checkAdmin(menuData.sideMenuId)" id="draft" :key="menuData.link" class="fold_on">
        <router-link :to="menuData.link" class="link_txt">
          <span class="ico_account" />{{ menuData.title }}
          <span v-if="isBadgeMenu(menuData.sideMenuId)" class="icon_new">NEW</span>
        </router-link>
        <ul class="list_subgnb">
          <li v-for="subMenuData in menuData.arrSub" :key="subMenuData.link" @click="getMenuCount">
            <router-link :to="subMenuData.link" class="link_txt">
              {{ subMenuData.title }}
              <span v-if="isBadgeSubMenu(subMenuData.sideMenuId)" class="badge_new">{{
                badgeSubMenuCount(subMenuData.sideMenuId)
              }}</span>
            </router-link>
          </li>
        </ul>
      </li>
    </template>
  </ul>
</template>

<script>
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
// import { default as CommGnbMixin } from '@/_approval/mixins/common/commGnb.js';
import { mapState } from "vuex";

import HeaderExtend from "../header/HeaderExtend.vue";

import sideMenuData from "@/constants/admin/sideMenus/sideMenuData";

// import menuId from '@/_approval/const/menuId';
// import sideMenuId from '@/_approval/const/_sideMenus/sideMenuId';
import { TOGGLE_SIDE_MENU, OPEN_SIDE_MENU } from "@/store/modules/purchaseRequest/action";
import LocalStorageManager from "@/LocalStorageManager";
// import routerPath from '@/router/routerPath';
import ApiService from "@/services/ApiService";
import sideMenuId from "@/constants/admin/sideMenus/sideMenuId";
import menuId from "@/constants/menuId";

export default {
  components: {
    HeaderExtend,
  },
  mixins: [CommLayerMixin],
  data() {
    return {
      // isDev: false,
      docCountData: [],
    };
  },
  computed: {
    ...mapState("CommStore", {
      login: (state) => state.login,
      openMenu: (state) => state.menu.openMenu,
      foldMenu: (state) => state.menu.foldMenu,
    }),
    ...mapState({
      menuOpenStatus: (state) => state.purchaseRequest.menuOpenStatus,
    }),
    selectedSideMenuId() {
      const { sideMenuId } = this.$route.meta;

      return sideMenuId;
    },
    // isStatementPage() {
    //   return this.$route.meta.menuId === menuId.STATEMENT;
    // },
    currentParentSideMenuId() {
      const { parentSideMenuId } = this.$route.meta;
      return parentSideMenuId;
    },
    isNotSuperOrAdmin() {
      return (
        LocalStorageManager.shared.getIsInspect() ||
        LocalStorageManager.shared.getIsRollUser() ||
        LocalStorageManager.shared.getIsRollUser().getIsPartner
      );
    },
    menuDatas() {
      const menus = sideMenuData;

      return menus;
    },
  },
  watch: {
    selectedSideMenuId(newVal, oldVal) {
      console.log(newVal);
      if (!newVal || newVal === oldVal) return;
      this.getMenuCount();
    },
  },

  beforeMount() {
    this.openSideMenu(this.currentParentSideMenuId);
  },
  mounted() {
    this.getMenuCount();
  },
  created() {},

  methods: {
    async getMenuCount() {
      let params = { targetGubun: "" };

      params.targetGubun = "STD";

      const res = await ApiService.shared.get(this.$apiPath.APRVL_DOC_COUNT, { params });

      this.docCountData = res.data;
    },
    isBadgeMenu(sideMenuId) {
      if (sideMenuId == "draft") {
        return this.badgeSubMenuCount("draftIngDocs") > 0;
      } else if (sideMenuId == "appr") {
        return this.badgeSubMenuCount("apprReceiveDocs") > 0;
      } else {
        return false;
      }
    },
    isBadgeSubMenu(sideMenuId) {
      return (
        (sideMenuId == "draftIngDocs" || sideMenuId == "apprReceiveDocs") &&
        this.badgeSubMenuCount(sideMenuId) > 0
      );
    },
    badgeSubMenuCount(sideMenuId) {
      let resultCount = 0;
      if (sideMenuId == "draftIngDocs") {
        resultCount = this.docCountData ? this.docCountData.onGoingDocCount : 0;
      } else if (sideMenuId == "apprReceiveDocs") {
        resultCount = this.docCountData ? this.docCountData.myAprovalDocCount : 0;
      }
      if (resultCount > 99) {
        resultCount = "99+";
      }
      return resultCount;
    },
    // toggleMenu(menuData) {
    //   // 현재페이지와 링크가 동일한 경우 라우터 오류 대응
    //   if (menuData.link === this.$route.path) return;
    //   // 정산서 등록 관리
    //   if (menuData.link === routerPath.STATEMENT_MANAGEMENT) {
    //     this.$router.push(menuData.link);
    //     return;
    //   }
    //
    //   this.$store.dispatch(TOGGLE_SIDE_MENU, {
    //     sideMenuId: menuData.sideMenuId,
    //   });
    // },
    openSideMenu(currentParentSideMenuId) {
      if (!currentParentSideMenuId) return;

      this.$store.dispatch(OPEN_SIDE_MENU, {
        sideMenuId: currentParentSideMenuId,
      });
    },
    // getIsMenuOpen(sMenuId) {
    //   switch (sMenuId) {
    //     case sideMenuId.DRAFT:
    //       return this.menuOpenStatus.draft;
    //     case sideMenuId.APPROVAL:
    //       return this.menuOpenStatus.approval;
    //     case sideMenuId.CC:
    //       return this.menuOpenStatus.cc;
    //     case sideMenuId.MANAGEMENT:
    //       return this.menuOpenStatus.admin;
    //     case sideMenuId.STATEMENT_MANAGEMENT:
    //       return this.menuOpenStatus.statementManagement;
    //   }
    // },
    // moveMenu(menuItem, isAdmin) {
    //   let foldItem = [];
    //   Array.from(document.getElementsByClassName('fold_on')).forEach(function (
    //     item,
    //   ) {
    //     foldItem.push(item.id);
    //   });
    //   this.$store.commit('CommStore/openMenu', {
    //     menuItem: menuItem,
    //     foldItem: foldItem,
    //   });
    //   let url = menuItem;
    //   if (isAdmin === 'admin') url = 'admin/' + menuItem;
    //   this.$router.push('/' + url);
    // },
    // backdoorLogin() {
    //   let layer = {};
    //   layer.type = 'popup';
    //   layer.class = '';
    //   layer.header = '우회 로그인';
    //   layer.componentFile = 'common/CommBackdoor.vue';
    //   this._showLayer(layer);
    // },
    checkAdmin(SideMenuId) {
      const roleList = this.$store.getters["getRoleList"];
      if (SideMenuId === sideMenuId.MANAGEMENT) {
        // 관리자
        return roleList.some((item) => item === menuId.PAYMENT_SYSTEM);
      } else {
        // 일반
        return roleList.some((item) => item === menuId.PAYMENT);
      }
      return true;
    },
  },
};
</script>
<style scoped>
.box_gnb .list_gnb {
  overflow-y: auto;
  max-height: 100%;
  padding: 31px 32px 30px 32px;
  box-sizing: border-box;
}

.box_gnb .list_gnb .link_txt {
  display: block;
  position: relative;
  padding-left: 12px;
  font-size: 15px;
  font-weight: 500;
  line-height: 36px;
  color: #222;
}
.box_gnb .list_gnb .link_txt:after {
  display: block;
  clear: both;
  content: "";
}
.box_gnb .list_gnb .link_txt:hover,
.box_gnb.list_gnb .link_txt:focus,
.box_gnb .list_subgnb .on .link_txt,
.box_gnb .list_subgnb .link_txt.router-link-active,
.box_gnb .list_subgnb .link_txt.router-link-exact-active {
  color: #555;
  text-decoration: underline;
}
.box_gnb .list_gnb .link_txt .ico_account {
  position: absolute;
  top: 14px;
  left: 0;
  width: 4px;
  height: 8px;
  background-position: -140px 0;
}
.box_gnb .list_gnb .icon_new {
  overflow: hidden;
  position: absolute;
  width: 4px;
  height: 4px;
  margin: 10px 0 0 4px;
  border-radius: 2px;
  line-height: 0;
  background-color: #ff586c;
  text-indent: -9999px;
}
.box_gnb .list_gnb .fold_on .list_subgnb {
  display: block;
}
.box_gnb .list_gnb .fold_on > .link_txt .ico_account {
  top: 16px;
  left: -2px;
  width: 8px;
  height: 4px;
  background-position: -130px 0;
}
.box_gnb .list_gnb .ico_alarm {
  float: right;
  margin-top: 9px;
  padding: 0 8px;
  font-size: 13px;
  color: #fff;
  border-radius: 10px;
  line-height: 20px;
  background: #ff586c;
}
.box_gnb .list_gnb .alarm_on .link_txt:after {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 5px 0 0 4px;
  border-radius: 3px;
  background: #ff586c;
  vertical-align: top;
  content: "";
}

.box_gnb .list_subgnb {
  display: none;
  padding: 0 0 10px 15px;
}
.box_gnb .list_subgnb .link_txt {
  padding-left: 12px;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  color: #888;
}
.box_gnb .list_subgnb .link_txt .ico_account {
  top: 8px;
}
.box_gnb .list_subgnb .link_txt .badge_new {
  float: right;
  margin: 2px 0;
  padding: 1px 5px;
  border-radius: 10px;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  background-color: #ff586c;
  color: #fff;
}
.box_gnb .list_subgnb .fold_on > .link_txt .ico_account {
  top: 9px;
}
</style>
